import { get, lowerCase } from '@antv/util';
import { createDarkTheme, createTheme } from './util';
var defaultTheme = createTheme({});
var darkTheme = createDarkTheme({});
// 所有已经存在的主题
var Themes = {
    default: defaultTheme,
    light: defaultTheme,
    dark: darkTheme,
};
/**
 * 获取主题配置信息。
 */
export function getTheme(theme) {
    if (theme === void 0) { theme = 'default'; }
    return get(Themes, lowerCase(theme), Themes['default']);
}
/**
 * 注册新的主题配置信息。
 */
export function registerTheme(theme, value, type) {
    if (type === void 0) { type = 'light'; }
    Themes[lowerCase(theme)] = type === 'light' ? createTheme(value) : createDarkTheme(value);
}
