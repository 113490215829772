import { __extends } from "tslib";
import { uniqueId } from '@antv/util';
import EventEmitter from '@antv/event-emitter';
var LayerGroup = /** @class */ (function (_super) {
    __extends(LayerGroup, _super);
    function LayerGroup(layers, option) {
        if (layers === void 0) { layers = []; }
        if (option === void 0) { option = {}; }
        var _this = _super.call(this) || this;
        _this.name = option.name ? option.name : uniqueId('layerGroup');
        _this.layers = layers;
        return _this;
    }
    /**
     * 图层组添加到地图上
     */
    LayerGroup.prototype.addTo = function (scene) {
        var _this = this;
        this.scene = scene;
        var layerIndex = 0;
        var layerLength = this.layers.length;
        this.layers.forEach(function (layer) {
            layer.once('inited', function (e) {
                layerIndex++;
                _this.emit('inited', e);
                if (layerIndex === layerLength) {
                    _this.emit('inited-all');
                }
            });
            layer.addTo(scene);
        });
    };
    /**
     * 图层组是否有该图层
     */
    LayerGroup.prototype.hasLayer = function (layer) {
        return this.layers.some(function (itemLayer) { return itemLayer === layer; });
    };
    /**
     * 增加图层
     */
    LayerGroup.prototype.addLayer = function (layer) {
        var _this = this;
        // TODO: duplicate layer
        this.layers.push(layer);
        if (this.scene) {
            layer.once('inited', function (e) { return _this.emit('inited', e); });
            layer.addTo(this.scene);
        }
    };
    /**
     * 移除 layer 图层
     */
    LayerGroup.prototype.removeLayer = function (layer) {
        var layerIndex = this.layers.findIndex(function (itemLayer) { return itemLayer === layer; });
        if (layerIndex === -1)
            return false;
        this.layers.splice(layerIndex, 1);
        if (this.scene) {
            layer.remove(this.scene);
        }
        return true;
    };
    /**
     * 获取所有的地图图层
     */
    LayerGroup.prototype.getLayers = function () {
        return this.layers;
    };
    /**
     * 获取所有的带交互图层
     */
    LayerGroup.prototype.getInteractionLayers = function () {
        return this.layers.filter(function (_a) {
            var interaction = _a.interaction;
            return interaction;
        });
    };
    /**
     * 根据图层 ID 获取图层对象
     */
    LayerGroup.prototype.getLayer = function (id) {
        return this.layers.find(function (_a) {
            var layer = _a.layer;
            return layer.id === id;
        });
    };
    /**
     * 根据图层 name 获取图层对象
     */
    LayerGroup.prototype.getLayerByName = function (name) {
        return this.layers.find(function (itemLayer) { return itemLayer.name === name; });
    };
    /**
     * 移除所有的图层对象
     */
    LayerGroup.prototype.removeAllLayer = function () {
        var _this = this;
        this.layers.forEach(function (layer) {
            if (_this.scene) {
                layer.remove(_this.scene);
            }
        });
        this.layers = [];
    };
    /**
     * 是否图层组为空
     */
    LayerGroup.prototype.isEmpty = function () {
        return this.layers.length === 0;
    };
    return LayerGroup;
}(EventEmitter));
export { LayerGroup };
