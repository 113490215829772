import { __extends } from "tslib";
import { Control } from '@antv/l7-component';
import { CategoryLegend, ContinueLegend } from '@antv/l7plot-component';
var Legend = /** @class */ (function (_super) {
    __extends(Legend, _super);
    function Legend(options) {
        var _this = _super.call(this, options) || this;
        /**
         * legendComponents 实例
         */
        _this.legendComponents = [];
        _this.options = options;
        _this.legendComponents = _this.initLegendComponents(options.items);
        return _this;
    }
    Legend.prototype.initLegendComponents = function (legendItem) {
        var legendComponents = [];
        for (var index = 0; index < legendItem.length; index++) {
            var item = legendItem[index];
            if (item.type === 'category') {
                var legend = new CategoryLegend({
                    title: item.options.title,
                    items: item.options.items,
                    className: item.options.className,
                    customContent: item.options.customContent,
                    domStyles: item.options.domStyles,
                });
                legendComponents.push(legend);
            }
            else if (item.type === 'continue') {
                var legend = new ContinueLegend({
                    title: item.options.title,
                    min: item.options.min,
                    max: item.options.max,
                    colors: item.options.colors,
                    className: item.options.className,
                    customContent: item.options.customContent,
                    domStyles: item.options.domStyles,
                });
                legendComponents.push(legend);
            }
        }
        return legendComponents;
    };
    /**
     * 获取默认配置
     */
    Legend.prototype.getDefaultOptions = function () {
        return {
            position: 'bottomleft',
        };
    };
    Legend.prototype.onAdd = function () {
        var container = window.document.createElement('div');
        container.className = 'l7plot-legend-container';
        this.legendComponents.forEach(function (legendComponent) {
            var legend = legendComponent.getContainer();
            container.appendChild(legend);
        });
        return container;
    };
    Legend.prototype.onRemove = function () {
        this.legendComponents.forEach(function (legendComponent) {
            legendComponent.destroy();
        });
    };
    return Legend;
}(Control));
export { Legend };
