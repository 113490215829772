"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RIBBON_TPL = exports.CONTAINER_TPL = exports.VALUE_RANGE_CLASS = exports.GRADIENT_BAR_CLASS = exports.RIBBON_CLASS = exports.RIBBON_CONTAINER_CLASS = exports.TITLE_CLASS = exports.CONTAINER_CLASS = void 0;
exports.CONTAINER_CLASS = 'l7plot-legend l7plot-legend__continue';
exports.TITLE_CLASS = 'l7plot-legend__title';
exports.RIBBON_CONTAINER_CLASS = 'l7plot-legend__ribbon-container';
exports.RIBBON_CLASS = 'l7plot-legend__ribbon';
exports.GRADIENT_BAR_CLASS = 'l7plot-legend__gradient-bar';
exports.VALUE_RANGE_CLASS = 'l7plot-legend__value-range';
exports.CONTAINER_TPL = "<div class=\"".concat(exports.CONTAINER_CLASS, "\">\n  <div class=\"").concat(exports.TITLE_CLASS, "\"></div>\n  <div class=\"").concat(exports.RIBBON_CONTAINER_CLASS, "\"></div>\n</div>");
exports.RIBBON_TPL = "<div class=\"".concat(exports.RIBBON_CLASS, "\">\n  <span class=\"").concat(exports.VALUE_RANGE_CLASS, "\">{min}</span>\n  <div class=\"").concat(exports.GRADIENT_BAR_CLASS, "\" style=\"background-image: {backgroundImage}\"></div>\n  <span class=\"").concat(exports.VALUE_RANGE_CLASS, "\">{max}</span>\n</div>");
