import { __extends, __rest } from "tslib";
import { isEqual, isUndefined, pick } from '@antv/util';
import Source from '@antv/l7-source';
import EventEmitter from '@antv/event-emitter';
import { LayerType } from '../../types/layer';
import { MappingSource } from '../../adaptor/source';
import { LayerEventList } from '../map/constants';
import { deepAssign } from '../../utils';
var LayerConfigkeys = ['name', 'zIndex', 'visible', 'minZoom', 'maxZoom', 'pickingBuffer', 'autoFit', 'blend'];
var PlotLayer = /** @class */ (function (_super) {
    __extends(PlotLayer, _super);
    function PlotLayer(options) {
        var _this = _super.call(this) || this;
        _this.options = deepAssign({}, _this.getDefaultOptions(), options);
        _this.lastOptions = _this.options;
        return _this;
    }
    /**
     * 获取默认配置
     */
    PlotLayer.prototype.getDefaultOptions = function () {
        return {};
    };
    PlotLayer.prototype.pickLayerConfig = function (params) {
        var config = pick(params, LayerConfigkeys);
        return config;
    };
    PlotLayer.prototype.addTo = function (scene) {
        scene.addLayer(this.layer);
    };
    PlotLayer.prototype.remove = function (scene) {
        scene.removeLayer(this.layer);
    };
    /**
     * 更新
     */
    PlotLayer.prototype.update = function (options) {
        this.updateOption(options);
        this.updateConfig(options);
    };
    /**
     * 更新: 更新配置
     */
    PlotLayer.prototype.updateOption = function (options) {
        this.lastOptions = this.options;
        this.options = deepAssign({}, this.options, options);
    };
    // 更新: 更新图层属性配置
    PlotLayer.prototype.updateConfig = function (options) {
        if (!isUndefined(options.zIndex) && !isEqual(this.lastOptions.zIndex, this.options.zIndex)) {
            this.setIndex(options.zIndex);
        }
        if (!isUndefined(options.blend) && !isEqual(this.lastOptions.blend, this.options.blend)) {
            this.setBlend(options.blend);
        }
        if (!isUndefined(options.minZoom) && !isEqual(this.lastOptions.minZoom, this.options.minZoom)) {
            this.setMinZoom(options.minZoom);
        }
        if (!isUndefined(options.maxZoom) && !isEqual(this.lastOptions.maxZoom, this.options.maxZoom)) {
            this.setMinZoom(options.maxZoom);
        }
        if (!isUndefined(options.visible) && !isEqual(this.lastOptions.visible, this.options.visible)) {
            options.visible ? this.show() : this.hide();
        }
    };
    PlotLayer.prototype.render = function () {
        this.layer.renderLayers();
    };
    PlotLayer.prototype.setSource = function (source) {
        if (source instanceof Source) {
            this.layer.setSource(source);
        }
        else {
            var data = source.data, aggregation = source.aggregation, option = __rest(source, ["data", "aggregation"]);
            aggregation && MappingSource.aggregation(option, aggregation);
            var layerSource = this.layer.getSource();
            if (layerSource) {
                this.layer.setData(data, option);
            }
            else {
                this.layer.source(data, option);
            }
        }
    };
    PlotLayer.prototype.changeData = function (source) {
        this.setSource(source);
    };
    PlotLayer.prototype.setIndex = function (zIndex) {
        this.layer.setIndex(zIndex);
    };
    PlotLayer.prototype.setBlend = function (blend) {
        this.layer.setBlend(blend);
    };
    PlotLayer.prototype.setMinZoom = function (minZoom) {
        this.layer.setMinZoom(minZoom);
    };
    PlotLayer.prototype.setMaxZoom = function (maxZoom) {
        this.layer.setMaxZoom(maxZoom);
    };
    PlotLayer.prototype.show = function () {
        this.layer.inited && this.layer.show();
    };
    PlotLayer.prototype.hide = function () {
        this.layer.inited && this.layer.hide();
    };
    PlotLayer.prototype.toggleVisible = function () {
        this.isVisible() ? this.hide() : this.show();
    };
    PlotLayer.prototype.isVisible = function () {
        return this.layer.inited ? this.layer.isVisible() : this.options.visible;
    };
    PlotLayer.prototype.fitBounds = function (fitBoundsOptions) {
        this.layer.fitBounds(fitBoundsOptions);
    };
    /**
     * 事件代理: 绑定事件
     */
    PlotLayer.prototype.on = function (name, callback) {
        if (LayerEventList.indexOf(name) !== -1) {
            this.layer.on(name, callback);
        }
        else {
            _super.prototype.on.call(this, name, callback);
        }
        return this;
    };
    /**
     * 事件代理: 绑定一次事件
     */
    PlotLayer.prototype.once = function (name, callback) {
        if (LayerEventList.indexOf(name) !== -1) {
            this.layer.once(name, callback);
        }
        else {
            _super.prototype.once.call(this, name, callback);
        }
        return this;
    };
    /**
     * 事件代理: 解绑事件
     */
    PlotLayer.prototype.off = function (name, callback) {
        if (LayerEventList.indexOf(name) !== -1) {
            this.layer.off(name, callback);
        }
        else {
            _super.prototype.off.call(this, name, callback);
        }
        return this;
    };
    /**
     * 地图图表类型
     */
    PlotLayer.LayerType = LayerType;
    /**
     * 图层属性配置项 Keys
     */
    PlotLayer.LayerConfigkeys = LayerConfigkeys;
    return PlotLayer;
}(EventEmitter));
export { PlotLayer };
