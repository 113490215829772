import { __assign, __extends } from "tslib";
import { pick } from '@antv/util';
import { Plot } from '../../core/plot';
import { DEFAULT_OPTIONS } from './constants';
import { DotLayer } from '../../layers/dot-layer';
import { LayerGroup } from '../../core/layer/layer-group';
var Dot = /** @class */ (function (_super) {
    __extends(Dot, _super);
    function Dot() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图表类型
         */
        _this.type = Plot.PlotType.Dot;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Dot.prototype.getDefaultOptions = function () {
        return Dot.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Dot.prototype.createLayers = function (source) {
        this.dotLayer = new DotLayer(__assign({ source: source }, pick(this.options, DotLayer.LayerOptionsKeys)));
        var layerGroup = new LayerGroup([this.dotLayer]);
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(source, this.options.label, this.options);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Dot.prototype.updateLayers = function (options) {
        var dotLayerConfig = pick(options, DotLayer.LayerOptionsKeys);
        this.dotLayer.update(dotLayerConfig);
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 实现 legend 配置项
     */
    Dot.prototype.getLegendOptions = function () {
        var colorLegendItems = this.dotLayer.getColorLegendItems();
        if (colorLegendItems.length !== 0) {
            return { type: 'category', items: colorLegendItems };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Dot.DefaultOptions = DEFAULT_OPTIONS;
    return Dot;
}(Plot));
export { Dot };
