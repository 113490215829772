/**
 * 自定义添加图片资源
 */
export var IMAGES_CACHE = new Map();
export function registerImage(id, image) {
    IMAGES_CACHE.set(id, image);
}
export function registerImages(images) {
    images.forEach(function (iconFont) {
        IMAGES_CACHE.set(iconFont.id, iconFont.image);
    });
}
export function getImage(id) {
    return IMAGES_CACHE.get(id);
}
export function hasImage(id) {
    return IMAGES_CACHE.has(id);
}
export function unregisterImage(id) {
    return IMAGES_CACHE.delete(id);
}
/**
 * 自定义添加第三方字体
 */
export var FONT_FACE_CACHE = new Map();
export function registerFontFace(fontFamily, fontPath) {
    FONT_FACE_CACHE.set(fontFamily, fontPath);
}
export function unregisterFontFace(fontFamily) {
    return FONT_FACE_CACHE.delete(fontFamily);
}
/**
 * 自定义添加 iconfont
 */
export var ICON_FONT_CACHE = new Map();
export function registerIconFont(name, fontUnicode) {
    ICON_FONT_CACHE.set(name, fontUnicode);
}
export function registerIconFonts(iconFonts) {
    iconFonts.forEach(function (iconFont) {
        ICON_FONT_CACHE.set(iconFont.name, iconFont.fontUnicode);
    });
}
export function unregisterIconFont(name) {
    return ICON_FONT_CACHE.delete(name);
}
