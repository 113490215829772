import { MappingLayer } from '../../adaptor/layer';
export function mappingLayer(layer, options) {
    var field = options.field, content = options.content, 
    // rotate,
    _a = options.style, 
    // rotate,
    style = _a === void 0 ? {} : _a, state = options.state;
    var fontSize = style.fontSize, fill = style.fill;
    // mapping shape
    var txet = field ? field : content ? content : '';
    var shape = { field: txet, value: 'text' };
    MappingLayer.shape(layer, shape);
    // mapping size
    fontSize && MappingLayer.size(layer, fontSize);
    // mapping color
    fill && MappingLayer.color(layer, fill);
    // mapping style
    style && MappingLayer.style(layer, style);
    // mapping rotate
    // rotate && MappingLayer.rotate(layer, rotate);
    // mapping state
    state && MappingLayer.state(layer, state);
}
