// 最大比对层级
var MAX_MIX_LEVEL = 5;
var toString = new Object().toString;
var isType = function (value, type) { return toString.call(value) === '[object ' + type + ']'; };
var isArray = function (value) {
    return isType(value, 'Array');
};
var isObjectLike = function (value) {
    /**
     * isObjectLike({}) => true
     * isObjectLike([1, 2, 3]) => true
     * isObjectLike(Function) => false
     */
    return typeof value === 'object' && value !== null;
};
var isPlainObject = function (value) {
    /**
     * isObjectLike(new Foo) => false
     * isObjectLike([1, 2, 3]) => false
     * isObjectLike({ x: 0, y: 0 }) => true
     */
    if (!isObjectLike(value) || !isType(value, 'Object')) {
        return false;
    }
    var proto = value;
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }
    return Object.getPrototypeOf(value) === proto;
};
var deep = function (target, source, level, maxLevel) {
    level = level || 0;
    maxLevel = maxLevel || MAX_MIX_LEVEL;
    for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            var value = source[key];
            if (!value) {
                // null 、 undefined 等情况直接赋值
                target[key] = value;
            }
            else {
                if (isPlainObject(value)) {
                    if (!isPlainObject(target[key])) {
                        target[key] = {};
                    }
                    if (level < maxLevel) {
                        deep(target[key], value, level + 1, maxLevel);
                    }
                    else {
                        // 层级过深直接赋值，性能问题
                        target[key] = source[key];
                    }
                }
                else if (isArray(value)) {
                    target[key] = [];
                    target[key] = target[key].concat(value);
                }
                else {
                    target[key] = value;
                }
            }
        }
    }
};
/**
 * deepAssign 对象深度合并
 * deepAssign 功能类似 deepMix
 * 不同点在于 deepAssign 会将 null undefined 等类型直接覆盖给 source
 */
export var deepAssign = function (target) {
    var sources = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        sources[_i - 1] = arguments[_i];
    }
    for (var i = 0; i < sources.length; i += 1) {
        deep(target, sources[i]);
    }
    return target;
};
