/**
 * 地图图表类型
 */
export var PlotType;
(function (PlotType) {
    PlotType["Dot"] = "dot";
    PlotType["DotDensity"] = "dotDensity";
    PlotType["Heatmap"] = "heatmap";
    PlotType["Grid"] = "grid";
    PlotType["Hexbin"] = "hexbin";
    PlotType["Path"] = "path";
    PlotType["Flow"] = "flow";
    PlotType["Area"] = "area";
    PlotType["Choropleth"] = "choropleth";
})(PlotType || (PlotType = {}));
