import { __assign, __extends } from "tslib";
import { uniqueId } from '@antv/util';
import { PointLayer } from '@antv/l7-layers';
import { PlotLayer } from '../../core/layer/plot-layer';
import { mappingLayer } from './adaptor';
import { getColorLegendItems } from './helper';
var DEFAULT_OPTIONS = {
    source: {
        data: [],
        parser: {
            type: 'json',
            x: 'x',
            y: 'y',
        },
    },
    shape: 'circle',
    size: 12,
    color: '#5FD3A6',
};
var LAYER_OPTIONS_KEYS = ['shape', 'color', 'size', 'style', 'state', 'animate'];
var DotLayer = /** @class */ (function (_super) {
    __extends(DotLayer, _super);
    function DotLayer(options) {
        var _this = _super.call(this, options) || this;
        /**
         * 图层类型
         */
        _this.type = PlotLayer.LayerType.DotLayer;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = true;
        var _a = _this.options, name = _a.name, source = _a.source;
        var config = _this.pickLayerConfig(_this.options);
        _this.name = name ? name : uniqueId(_this.type);
        _this.layer = new PointLayer(__assign(__assign({}, config), { name: _this.name }));
        _this.mappingLayer(_this.layer, _this.options);
        _this.setSource(source);
        return _this;
    }
    /**
     * 获取默认配置
     */
    DotLayer.prototype.getDefaultOptions = function () {
        return DEFAULT_OPTIONS;
    };
    DotLayer.prototype.mappingLayer = function (layer, options) {
        mappingLayer(layer, options);
    };
    DotLayer.prototype.update = function (options) {
        _super.prototype.update.call(this, options);
        this.mappingLayer(this.layer, this.options);
    };
    DotLayer.prototype.getColorLegendItems = function () {
        var colorLegendItems = this.layer.getLegendItems('color');
        if (Array.isArray(colorLegendItems) && colorLegendItems.length !== 0) {
            var items = getColorLegendItems(colorLegendItems);
            return items;
        }
        return [];
    };
    /**
     * 默认配置项
     */
    DotLayer.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 图层配置项 Keys
     */
    DotLayer.LayerOptionsKeys = PlotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return DotLayer;
}(PlotLayer));
export { DotLayer };
