export var SceneEventList = [
    // scene 初始化完成事件
    { original: 'loaded', adaptation: 'scene-loaded' },
    // 地图容器变化事件
    { original: 'resize', adaptation: 'resize' },
    // scene 摧毁事件
    { original: 'destroy', adaptation: 'destroy' },
    // 地图容器变化事件
    { original: 'resize', adaptation: 'resize' },
];
export var MapEventList = [
    // 地图事件
    'mapmove',
    'movestart',
    'moveend',
    'zoomchange',
    'zoomstart',
    'zoomend',
    // 鼠标事件
    // 单击事件
    'click',
    // 双击事件
    'dblclick',
    // 右键事件
    'contextmenu',
    // mouse 事件
    'mousemove',
    'mousewheel',
    'mouseover',
    'mouseout',
    'mouseup',
    'mousedown',
    // drag 事件
    'dragstart',
    'dragging',
    'dragend',
];
export var LayerEventList = [
    // 图层事件
    'inited',
    'add',
    'remove',
    'dataUpdate',
    // 鼠标事件
    // 单击事件
    'click',
    'unclick',
    // 右键事件
    'contextmenu',
    'uncontextmenu',
    // mouse 事件
    'mouseenter',
    'mousemove',
    'unmousemove',
    'mouseout',
    'mouseup',
    'unmouseup',
    'mousedown',
    'uncontextmenu',
    'unpick',
];
