import { __extends } from "tslib";
import { Marker } from '@antv/l7-component';
import EventEmitter from '@antv/event-emitter';
import { isString, isEqual } from '@antv/util';
import { Tooltip as TooltipComponent, } from '@antv/l7plot-component';
import { get as getValueByPath } from 'lodash-es';
import { TooltipAnchorType, } from '../types';
import { deepAssign } from '../utils';
var TRIGGER_LIST = ['mousemove', 'click'];
var Tooltip = /** @class */ (function (_super) {
    __extends(Tooltip, _super);
    function Tooltip(scene, interactionLayers, options) {
        var _this = _super.call(this) || this;
        /**
         * tooltip 是否可见
         */
        _this.currentVisible = false;
        _this.interactionTriggerHander = function (event) {
            var feature = event.feature, featureId = event.featureId;
            var _a = _this.options, title = _a.title, customTitle = _a.customTitle, items = _a.items, customItems = _a.customItems;
            // is GeoJson type
            var isGeoFeature = feature.type === 'Feature' && feature.geometry && feature.properties;
            // parse GeoJson properties
            var properties = isGeoFeature ? feature.properties : feature;
            var tooltipItems = [];
            if (customItems) {
                var items_1 = customItems(feature);
                if (Array.isArray(items_1)) {
                    tooltipItems = items_1;
                }
                else {
                    throw new Error('customItems return array');
                }
            }
            else if (items) {
                items.forEach(function (item) {
                    if (isString(item)) {
                        var name_1 = item.split('.').pop() || item;
                        var value = getValueByPath(properties, item);
                        if (value !== undefined) {
                            tooltipItems.push({ name: name_1, value: value });
                        }
                    }
                    else {
                        var field = item.field, alias = item.alias, customValue = item.customValue;
                        var name_2 = alias || field.split('.').pop() || field;
                        var value = getValueByPath(properties, field);
                        if (value !== undefined) {
                            tooltipItems.push({
                                name: name_2,
                                value: customValue ? customValue(value, properties, featureId) : value,
                            });
                        }
                    }
                });
            }
            var componentOptions = { title: customTitle ? customTitle(properties) : title, items: tooltipItems };
            _this.updateTooltip(event, componentOptions);
        };
        _this.interactionUntriggerHander = function () {
            _this.hideTooltip();
        };
        _this.scene = scene;
        _this.interactionLayers = interactionLayers;
        _this.options = deepAssign({}, _this.getDefaultOptions(), options);
        var _a = _this.options, offsets = _a.offsets, title = _a.title, showTitle = _a.showTitle, customContent = _a.customContent, domStyles = _a.domStyles, anchor = _a.anchor, className = _a.className;
        _this.marker = new Marker({
            offsets: offsets,
            anchor: anchor,
            draggable: false,
        });
        _this.tooltipComponent = new TooltipComponent({
            title: title,
            showTitle: showTitle,
            items: [],
            customContent: customContent,
            domStyles: domStyles,
            className: className,
        });
        _this.setComponent();
        _this.initInteractionEvent();
        return _this;
    }
    /**
     * 获取默认配置
     */
    Tooltip.prototype.getDefaultOptions = function () {
        return {
            showTitle: true,
            showComponent: true,
            items: [],
            offsets: [15, 0],
            trigger: 'mousemove',
            anchor: TooltipAnchorType['TOP-LEFT'],
        };
    };
    /**
     * 更新 tooltip 组件
     */
    Tooltip.prototype.update = function (options) {
        this.marker.remove();
        this.currentVisible = false;
        this.options = deepAssign({}, this.options, options);
        var _a = this.options, offsets = _a.offsets, showTitle = _a.showTitle, customContent = _a.customContent, domStyles = _a.domStyles, anchor = _a.anchor, className = _a.className;
        this.marker = new Marker({
            offsets: offsets,
            anchor: anchor,
            draggable: false,
        });
        this.tooltipComponent.update({
            showTitle: showTitle,
            customContent: customContent,
            domStyles: domStyles,
            className: className,
        });
        this.setComponent();
    };
    Tooltip.prototype.initInteractionEvent = function () {
        var _this = this;
        var trigger = this.options.trigger || 'mousemove';
        if (!TRIGGER_LIST.includes(trigger)) {
            throw new Error('trigger is mousemove or click');
        }
        this.interactionLayers.forEach(function (_a) {
            var layer = _a.layer;
            layer.on(trigger, _this.interactionTriggerHander);
            layer.on("un".concat(trigger), _this.interactionUntriggerHander);
        });
    };
    Tooltip.prototype.unBindInteractionEvent = function () {
        var _this = this;
        var trigger = this.options.trigger || 'mousemove';
        this.interactionLayers.forEach(function (_a) {
            var layer = _a.layer;
            layer.off(trigger, _this.interactionTriggerHander);
            layer.off("un".concat(trigger), _this.interactionUntriggerHander);
        });
    };
    Tooltip.prototype.updateTooltip = function (mouseEvent, componentOptions) {
        var lngLat = mouseEvent.lngLat, x = mouseEvent.x, y = mouseEvent.y;
        if (this.options.showComponent) {
            this.updateComponent(componentOptions);
            this.setPostion(lngLat);
        }
        if (this.currentVisible) {
            var event_1 = { type: 'tooltip:change', data: componentOptions, lngLat: lngLat, x: x, y: y };
            this.emit('tooltip:change', event_1);
        }
        else {
            this.showTooltip();
            var event_2 = { type: 'tooltip:show', data: componentOptions, lngLat: lngLat, x: x, y: y };
            this.emit('tooltip:show', event_2);
        }
    };
    /**
     * tooltip 添加到地图上
     */
    Tooltip.prototype.showTooltip = function () {
        if (this.currentVisible)
            return;
        if (this.options.showComponent) {
            this.scene.addMarker(this.marker);
        }
        this.currentVisible = true;
    };
    /**
     * tooltip 从地图上移除
     */
    Tooltip.prototype.hideTooltip = function () {
        if (!this.currentVisible)
            return;
        if (this.options.showComponent) {
            this.marker.remove();
        }
        this.currentVisible = false;
        var event = { type: 'tooltip:hide' };
        this.emit('tooltip:hide', event);
    };
    /**
     * 更新 tooltip 组件
     */
    Tooltip.prototype.updateComponent = function (componentOptions) {
        if (!isEqual(this.lastComponentOptions, componentOptions)) {
            this.tooltipComponent.update(componentOptions);
            this.lastComponentOptions = componentOptions;
        }
    };
    /**
     * 设置 tooltip 内容
     */
    Tooltip.prototype.setComponent = function () {
        var tooltip = this.tooltipComponent.getContainer();
        var container = window.document.createElement('div');
        container.style.cursor = 'auto';
        container.style.userSelect = 'text';
        container.className = 'l7plot-tooltip-container';
        // stopPropagation
        ['mousemove', 'mousedown', 'mouseup', 'click', 'dblclick'].forEach(function (type) {
            container.addEventListener(type, function (e) { return e.stopPropagation(); });
        });
        container.appendChild(tooltip);
        this.marker.setElement(container);
    };
    /**
     * 设置 tooltip 位置
     */
    Tooltip.prototype.setPostion = function (position) {
        this.marker.setLnglat(position);
    };
    /**
     * 销毁
     */
    Tooltip.prototype.destroy = function () {
        this.unBindInteractionEvent();
        this.off();
        this.marker.remove();
        this.tooltipComponent.destroy();
    };
    return Tooltip;
}(EventEmitter));
export { Tooltip };
