import { MappingLayer } from '../../adaptor/layer';
function getRampColors(style) {
    var colorsRamp = style.colorsRamp;
    var rampColors = { colors: [], positions: [] };
    colorsRamp.forEach(function (_a) {
        var color = _a.color, position = _a.position;
        rampColors.colors.push(color);
        rampColors.positions.push(position);
    });
    return rampColors;
}
export function mappingLayer(layer, options) {
    var shape = options.shape, size = options.size, style = options.style, state = options.state;
    // mapping shape
    shape && MappingLayer.shape(layer, shape);
    // mapping size
    size && MappingLayer.size(layer, size);
    // mapping style
    if (style) {
        if ('colorsRamp' in style) {
            style.rampColors = getRampColors(style);
        }
        MappingLayer.style(layer, style);
    }
    // mapping state
    state && MappingLayer.state(layer, state);
}
