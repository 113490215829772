"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ITEM_TPL = exports.CONTAINER_TPL = exports.VALUE_CLASS = exports.MARKER_CLASS = exports.LIST_ITEM_CLASS = exports.LIST_CLASS = exports.TITLE_CLASS = exports.CONTAINER_CLASS = void 0;
exports.CONTAINER_CLASS = 'l7plot-legend l7plot-legend__category';
exports.TITLE_CLASS = 'l7plot-legend__title';
exports.LIST_CLASS = 'l7plot-legend__category-list';
exports.LIST_ITEM_CLASS = 'l7plot-legend__list-item';
exports.MARKER_CLASS = 'l7plot-legend__category-marker';
exports.VALUE_CLASS = 'l7plot-legend__category-value';
exports.CONTAINER_TPL = "<div class=\"".concat(exports.CONTAINER_CLASS, "\">\n  <div class=\"").concat(exports.TITLE_CLASS, "\"></div>\n  <ul class=\"").concat(exports.LIST_CLASS, "\"></ul>\n</div>");
exports.ITEM_TPL = "<li class=\"".concat(exports.LIST_ITEM_CLASS, "\" data-index={index}>\n  <span class=\"").concat(exports.MARKER_CLASS, "\" style=\"background:{color}\"></span>\n  <span class=\"").concat(exports.VALUE_CLASS, "\">{value}</span>\n</li>");
