import { __assign, __extends } from "tslib";
import { uniqueId } from '@antv/util';
import { PointLayer } from '@antv/l7-layers';
import { PlotLayer } from '../../core/layer/plot-layer';
import { mappingLayer } from './adaptor';
var DEFAULT_OPTIONS = {
    style: {
        fontSize: 12,
    },
};
var LAYER_OPTIONS_KEYS = [];
var TextLayer = /** @class */ (function (_super) {
    __extends(TextLayer, _super);
    function TextLayer(options) {
        var _this = _super.call(this, options) || this;
        /**
         * 图层类型
         */
        _this.type = PlotLayer.LayerType.TextLayer;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = false;
        var _a = _this.options, name = _a.name, source = _a.source;
        var config = _this.pickLayerConfig(_this.options);
        _this.name = name ? name : uniqueId(_this.type);
        _this.layer = new PointLayer(__assign(__assign({}, config), { name: _this.name }));
        _this.mappingLayer(_this.layer, _this.options);
        _this.setSource(source);
        return _this;
    }
    /**
     * 获取默认配置
     */
    TextLayer.prototype.getDefaultOptions = function () {
        return DEFAULT_OPTIONS;
    };
    TextLayer.prototype.mappingLayer = function (layer, options) {
        mappingLayer(layer, options);
    };
    TextLayer.prototype.update = function (options) {
        _super.prototype.update.call(this, options);
        this.mappingLayer(this.layer, this.options);
    };
    /**
     * 默认配置项
     */
    TextLayer.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 图层配置项 Keys
     */
    TextLayer.LayerOptionsKeys = PlotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return TextLayer;
}(PlotLayer));
export { TextLayer };
