import { MappingLayer } from '../../adaptor/layer';
export function mappingLayer(layer, options) {
    var shape = options.shape, color = options.color, size = options.size, style = options.style, state = options.state, animate = options.animate;
    // mapping shape
    shape && MappingLayer.shape(layer, shape);
    // mapping size
    size && MappingLayer.size(layer, size);
    // mapping color
    color && MappingLayer.color(layer, color);
    // mapping style
    style && MappingLayer.style(layer, style);
    // mapping state
    state && MappingLayer.state(layer, state);
    // mapping animate
    animate && MappingLayer.animate(layer, animate);
}
