import { __assign, __extends } from "tslib";
import { pick } from '@antv/util';
import { Plot } from '../../core/plot';
import { DEFAULT_OPTIONS } from './constants';
import { HeatmapLayer } from '../../layers/heatmap-layer';
import { LayerGroup } from '../../core/layer/layer-group';
var Heatmap = /** @class */ (function (_super) {
    __extends(Heatmap, _super);
    function Heatmap() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 地图类型
         */
        _this.type = Plot.PlotType.Heatmap;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Heatmap.prototype.getDefaultOptions = function () {
        return Heatmap.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Heatmap.prototype.createLayers = function (source) {
        this.heatmapLayer = new HeatmapLayer(__assign({ source: source }, pick(this.options, HeatmapLayer.LayerOptionsKeys)));
        var layerGroup = new LayerGroup([this.heatmapLayer]);
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(this.source, this.options.label, this.options);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Heatmap.prototype.updateLayers = function (options) {
        var heatMapLayerConfig = pick(options, HeatmapLayer.LayerOptionsKeys);
        this.heatmapLayer.update(heatMapLayerConfig);
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 实现 legend 配置项
     */
    Heatmap.prototype.getLegendOptions = function () {
        var _a;
        var sizeLegendItems = this.heatmapLayer.layer.getLegendItems('size');
        if (Array.isArray(sizeLegendItems) && sizeLegendItems.length !== 0) {
            var min = sizeLegendItems[0].value;
            var max = sizeLegendItems[sizeLegendItems.length - 1].value;
            var colors = (_a = this.heatmapLayer.options.style) === null || _a === void 0 ? void 0 : _a.colorsRamp.map(function (_a) {
                var color = _a.color;
                return color;
            });
            return { type: 'continue', min: min, max: max, colors: colors };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Heatmap.DefaultOptions = DEFAULT_OPTIONS;
    return Heatmap;
}(Plot));
export { Heatmap };
