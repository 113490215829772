import { Map as MapboxglMap } from 'mapbox-gl';
import Source from '@antv/l7-source';
export { MapboxglMap, Source };
/**
 * 底图类型
 */
export var BaseMapType;
(function (BaseMapType) {
    BaseMapType["Amap"] = "amap";
    BaseMapType["Mapbox"] = "mapbox";
})(BaseMapType || (BaseMapType = {}));
