import { __assign } from "tslib";
import { round } from 'lodash-es';
var isLegendItemI = function (legendItems) {
    return !Array.isArray(legendItems[0].value);
};
export var getColorLegendItems = function (legendItems) {
    if (isLegendItemI(legendItems)) {
        return legendItems;
    }
    // TODO: scale nice
    var items = legendItems.map(function (item) { return (__assign(__assign({}, item), { value: [round(item.value[0], 2), round(item.value[1], 2)] })); });
    return items;
};
